import BottomButton from "@/components/BottomButton";
import sinopecInfectious from "@/components/documents/sinopecInfectious";
import sinopecCritically from "@/components/documents/sinopecCritically";
import sinopecDisclaimer from "@/components/documents/sinopecDisclaimer";
import sinopecNotice from "@/components/documents/sinopecNotice";
import sinopecDisclosure from "@/components/documents/sinopecDisclosure";
import sinopecPolicy from "@/components/documents/sinopecPolicy";
import sinopecDisease from "@/components/documents/sinopecDisease";

import { registerComponent } from "@/utils";
import { Tab, Tabs } from "vant";

export const components = registerComponent({
  Tab,
  Tabs,
  BottomButton,
  sinopecInfectious,
  sinopecCritically,
  sinopecDisclaimer,
  sinopecNotice,
  sinopecDisclosure,
  sinopecPolicy,
  sinopecDisease
});

export default { components };
